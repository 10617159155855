<template>
    <div v-if="product != null">
        <v-container>
            <v-card class="pa-4">

                <v-row>
                    <v-col v-for="(att, i) in product.attachments" :key="i" cols="3">
                        <v-img :src="att.path" class="border grey lighten-2" height="250"></v-img>
                    </v-col>

                    <v-col cols="3">
                        <video controls width="100%" height="250">
                            <source :src="product.videoUrl" type="video/mp4" />
                        </video>
                    </v-col>
                </v-row>

                <v-card-title class>
                    <div>
                        <span class="grey--text ml-2">اسم المنتج :</span>
                        {{ product.name }}
                    </div>
                    <v-spacer />
                    <v-chip color="primary secondary--text">{{ product.categoryName }}</v-chip>
                </v-card-title>

                <v-card-subtitle>
                    <div>
                        <span class="grey--text ml-2">الوصف :</span>
                        {{ product.description }}
                    </div>

                    <div>
                        <span class="grey--text ml-2">تاريخ الاضافة :</span>
                        {{ product.created | formatDate }}
                    </div>

                    <v-card flat class="mt-10 border">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>القياس</th>
                                        <th>الوصف</th>
                                        <th>السعر</th>
                                        <th>اقل كمية للبيع</th>
                                        <th>الخصم %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in product.sizes" :key="item.name">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.price | formatCurrency }}</td>
                                        <td>{{ item.discount }}</td>
                                        <td>{{ item.minNoSale }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-card-subtitle>

                <v-card-actions class="mt-2">
                    <v-btn @click="goBack()" color="primary secondary--text" large>
                        رجوع
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            product: this.$route.params.obj,
        };
    },

    methods: {
        goBack() {
            window.history.go(-1);
        },
    },
};
</script>
